<template>
  <div class="inner">
    <div class="tile_wrap">
      <div class="left">
        <div class="img">
          <img src="@/assets/img/index/pin.png" alt="" />
        </div>
        <span>优惠券</span>
      </div>
      <div class="right c_p" @click="handleJump(coupon.url)">
        <span>更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="cou_item" v-for="item in coupon.list" :key="item.id">
        <img src="@/assets/img/zone/yhq.png" alt="" />
        <div class="box">
          <p class="pr">
            ￥<span>{{ Number(item.hbje).toFixed(0) }}</span>
          </p>
          <div class="flex_1">
            <div class="p1">{{ item.yhq_str }}</div>
            <div>{{ item.qssj }}至{{ item.jssj }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
export default {
  props: {
    coupon: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
};
</script>
<style lang="less" scoped>
.inner {
  width: 374px;
  height: 389px;
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
      span {
        font-size: 24px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-style: italic;
        color: #f14948;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        line-height:100%;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .cont {
    padding: 0 20px;
    border-radius: 4px;
    .cou_item {
      width: 329px;
      height: 116px;
      position: relative;
      margin-top: 20px;
      img {
        position: absolute;
      }
      .box {
        width: 329px;
        height: 116px;
        position: absolute;
        display: flex;
        align-items: center;
        .pr {
          width: 130px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          span {
            font-size: 46px;
          }
        }
        .flex_1 {
          text-align: center;
          color: #ffffff;
          .p1 {
            font-size: 20px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
</style>
