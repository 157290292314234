<template>
  <div class="backdrop" :style="{ backgroundImage: `url(${data.floor[0].bj_img})` }">
    <div class="more" @click="handleJump(data.floor[0].jump)">更多 <i class="el-icon-arrow-right"></i></div>
    <div class="item_wrap">
      <div class="item" v-for="item in data.floor[0].goodsArr" :key="item.id" @click="handleDetail(item.id)">
        <div class="img">
          <img :src="item.image.url" alt="" />
        </div>
        <p class="pr">￥{{ item.price }}</p>
        <p class="til shenglue_1">{{ item.title }}</p>
        <p class="shenglue_1 f12">{{ item.specification }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.backdrop {
  width: 1200px;
  height: 730px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  position: relative;
  .more {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #333;
    cursor: pointer;
    font-size:12px;
  }
  .item_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      width: 221px;
      height: 275px;
      box-sizing: border-box;
      padding: 10px;
      border: 1px solid #f1622f;
      background: linear-gradient(0deg, #ffd4c4 0%, #ffffff 40%);
      box-shadow: 0px 0px 8px 0px rgba(241, 98, 47, 0.3);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:nth-child(-n + 5) {
        margin-bottom: 14px;
      }
      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
      }
      .img {
        margin-top: 10px;
        width: 150px;
        height: 150px;
        background-color: #eee;
      }
      p {
        color: #333;
      }
      .pr {
        color: @priceRed;
        font-size: 16px;
        font-weight: bold;
        margin: 8px 0;
      }
      .til {
        font-weight: bold;
        color: #333333;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
