<template>
  <div>
    <!-- 轮播 -->
    <banner />
    <section>
      <div class="home_container">
        <div class="content">
          <!-- &&  coupon.list.length -->
          <div  v-if="seckill.goods" class="box">
            <!-- 秒杀 -->
            <seckill v-if="seckill.goods" :seckill="seckill" :coupon="coupon" id="100" />
            <!-- 优惠券 -->
            <coupon v-if="coupon.list.length" :coupon="coupon" />
          </div>

          <div v-for="items in style_arr" :key="items.id" class="box">
            <!-- 大背景图 -->
            <backdrop v-if="items.lcys == 101" :data="items" :id="'101' + items.id" />

            <styleOne v-if="items.lcys == 102" :data="items" :id="'102' + items.id" />

            <styleTwo v-if="items.lcys == 103" :data="items" :id="'103' + items.id" />

            <styleThree v-if="items.lcys == 104" :data="items" :id="'104' + items.id" />

            <styleFive v-if="items.lcys == 105" :data="items" :id="'105' + items.id" />

            <styleSix v-if="items.lcys == 106" :data="items" :id="'106' + items.id" />
          </div>

          <brandBottom id="107" />
          
        </div>
      </div>
    </section>

    <!--左侧导航  -->
    <floornav :list="list" />

    <popup />

    <div v-if="qiandao_arr.isqd == 101" @click='homesign' class='qiandao'>
      <img :src="qiandao_arr.img" alt="" />
    </div>
  </div>
</template>

<script>
import banner from "./components/banner";
import seckill from "./components/area/seckill.vue";
import coupon from "./components/area/coupon.vue";
import backdrop from "./components/area/backdrop.vue";

import styleOne from "./components/area/styleOne.vue";
import styleTwo from "./components/area/styleTwo.vue";
import styleThree from "./components/area/styleThree.vue";
// import styleFour from "./components/area/styleFour.vue";
import styleFive from "./components/area/styleFive.vue";
import styleSix from "./components/area/styleSix.vue";

import brandBottom from "./components/area/brandBottom.vue";

import floornav from "@/components/floornav.vue";

import popup from "./components/popup/index.vue";
export default {
  components: {
    banner,
    seckill,
    coupon,
    backdrop,
    styleOne,
    styleTwo,
    styleThree,
    // styleFour,
    styleFive,
    styleSix,
    brandBottom,
    floornav,
    popup,
  },
  data() {
    return {
      list: [],
      seckill: {},
      coupon: {},
      style_arr: [],
      qiandao_arr:{
        isqd:'102'
      }
    };
  },
  created() {

    this.$api("home.getActivity").then((res) => {
      this.seckill = res.data.seckill;
      this.coupon = res.data.yhq;
      if (this.seckill && this.coupon.list.length) {
        this.list.push({
          title: res.data.seckill.title,
          id: "100",
        });
      }
      this.$api("home.getFloor").then((res) => {
        this.style_arr = res.data;
        res.data.forEach((v) => {
          if (v.lcys != 107) {
            this.list.push({ title: v.title, id: v.lcys + v.id });
          }
        });
        this.list.push({
          title: "品牌专区",
          id: "107",
        });
      });
    });
  },
  mounted() {
    this.getSign()
  },
  methods: {
    getSign(){
      this.$api("account.signread").then((res) => {
        this.qiandao_arr = res.data
      });
    },
    homesign(){
      this.$api("account.signsave").then(() => {
        this.$notify({
          title: '签到成功',
          // message: '这是一条成功的提示消息',
          type: 'success',
          position:'bottom-right',
          duration:'1500'
        });
        this.getSign()
        
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  position:relative;
  justify-content: space-between;
  .box {
    display: flex;
    justify-content: space-between;
    width: 1200px;
  }
  

}
.qiandao{
   position:fixed;
   bottom:100px;
   right:0px;
   width:100px;
   height:100px;
   background-color:red;
   z-index:10000
  }

</style>
