<template>
  <div class="bottom">
    <div class="tile_wrap">
      <div class="img">
        <img src="@/assets/img/index/pin.png" alt="" />
      </div>
      <span>品牌专区</span>
    </div>
    <div class="main">
      <div class="top_img">
        <div class="img" v-for="item in brand_adv" :key="item.id">
          <img :src="item.image.url" alt="" @click="click(item)" />
        </div>
      </div>

      <div class="wrapper">
        <div class="img" v-for="item in brand_swiper" :key="item.img">
          <img :src="item.img" alt="" @click="handleJump(item.jump)" />
        </div>
        <div class="flex_c_c c_p img" style="height: 100px; width: 100px" @click="handleMore"><i class="el-icon-refresh-left"></i>换一批</div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
import { DeepClone } from "@/utils/tool";
export default {
  data() {
    return {
      handleJump,
      brand_adv: [],
      brand_swiper: [],
      animateUp: false,
      timer: null,
    };
  },
  mounted() {
    this.$api("home.getBrand").then((res) => {
      this.brand_adv = res.data.brand_adv;
      this.brand_swiper = res.data.brand_swiper;
      // if (this.brand_swiper.length >= 10) {
      //   this.timer = setInterval(this.scrollAnimate, 2000);
      // }
    });
  },
  methods: {
    click(e) {
      this.$api("home.getClick", { adv_id: e.id });
      handleJump(e.jump);
    },
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.brand_swiper.push(this.brand_swiper[0]);
        this.brand_swiper.shift();
        this.animateUp = false;
      }, 500);
    },
    handleMore() {
      let arr = DeepClone(this.brand_swiper);
      let arr1 = this.shuffle(arr);
      this.brand_swiper = arr1;
    },
    shuffle(array) {
      var j, x, i;
      for (i = array.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = array[i - 1];
        array[i - 1] = array[j];
        array[j] = x;
      }
      return array;
    },
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateX(-110px);
}
.bottom {
  width: 100%;
  height: 405px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    .img {
      width: 29px;
      height: 29px;
      margin-right: 5px;
    }
    span {
      font-size: 22px;
      font-family: CKTKingKong;
      font-weight: bold;
      color: #f14a49;
    }
  }
  .main {
    padding: 20px;
    padding-top: 0;
    .top_img {
      display: flex;
      justify-content: space-between;
      .img {
        width: 380px;
        height: 190px;
        border-radius: 4px;
      }
    }
    .wrapper {
      display: flex;
      margin-top: 20px;
      overflow: hidden;
      .img {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        margin-right: 6px;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.6);
        }
      }
    }
  }
}
</style>
